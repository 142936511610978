import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  effect,
  ElementRef,
  forwardRef,
  inject,
  Inject,
  input,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  signal,
} from '@angular/core';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import {
  ControlValueAccessor,
  FormGroupDirective,
  FormsModule,
  NgControl,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { _ErrorStateTracker, ErrorStateMatcher } from '@angular/material/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';

type ValueType = string | number | null;
let idCount = 0;

@Component({
  selector: 'app-custom-textarea',
  standalone: true,
  imports: [CdkTextareaAutosize, MatInput, ReactiveFormsModule, FormsModule],
  templateUrl: './custom-textarea.component.html',
  styleUrl: './custom-textarea.component.scss',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => CustomTextareaComponent),
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.id]': 'id',
    '[attr.placeholder]': 'placeholder',
    '[attr.aria-required]': 'required.toString()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[attr.aria-invalid]': 'errorState',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTextareaComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<ValueType>,
    OnChanges,
    OnDestroy,
    DoCheck
{
  //injects
  private readonly cdr = inject(ChangeDetectorRef);
  private _elementRef = inject(ElementRef);

  //inputs
  maxLength = input<string | null>(null);
  minlength = input<string | null>(null);
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder = '';
  @Input({ transform: booleanAttribute })
  disabled: boolean = false;

  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }

  set required(value: boolean) {
    this._required = value;
    this.stateChanges.next();
  }

  private _required: boolean | undefined;

  private onChange!: (value: ValueType) => void;
  private onTouch!: () => void;

  stateChanges = new Subject<void>();
  id = `custom-textarea-${idCount++}`;
  $value = signal<ValueType>(null);
  get value(): ValueType {
    return this.$value();
  }

  set value(newValue: ValueType) {
    this.$value.set(newValue);
    this.stateChanges.next();
  }

  private _errorStateTracker: _ErrorStateTracker;

  focused = false;
  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.focused = false;
      this.onTouch();
      this.stateChanges.next();
    }
  }

  onBlur() {
    if (!this.disabled) {
      this.onTouch?.();
      this.cdr.markForCheck();
      this.stateChanges.next();
    }
  }

  get empty(): boolean {
    return !this.value;
  }

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get errorState() {
    return this._errorStateTracker.errorState;
  }

  set errorState(value: boolean) {
    this._errorStateTracker.errorState = value;
  }
  controlType?: string | undefined;
  autofilled?: boolean | undefined;
  disableAutomaticLabeling?: boolean | undefined;

  constructor(
    defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Optional()
    @Inject(MAT_FORM_FIELD)
    protected _parentFormField: MatFormField,
    @Self() @Optional() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this._errorStateTracker = new _ErrorStateTracker(
      defaultErrorStateMatcher,
      this.ngControl,
      parentFormGroup,
      parentForm,
      this.stateChanges
    );

    effect(() => {
      this.onChange?.(this.$value());
    });
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
      if (
        this.ngControl.disabled !== null &&
        this.ngControl.disabled !== this.disabled
      ) {
        this.disabled = this.ngControl.disabled;
        this.stateChanges.next();
      }
    }
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  updateErrorState() {
    this._errorStateTracker.updateErrorState();
  }

  setDescribedByIds(ids: string[]) {
    if (ids.length) {
      this._elementRef?.nativeElement.setAttribute(
        'aria-describedby',
        ids.join(' ')
      );
    } else {
      this._elementRef?.nativeElement.removeAttribute('aria-describedby');
    }
  }

  onContainerClick(): void {
    this.onFocusIn();
  }

  writeValue(obj: ValueType): void {
    this.value = obj;
  }
  registerOnChange(fn: (value: ValueType) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
